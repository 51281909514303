jQuery(function () {
    try {
        var myOffside = offside("#mobileMenu", {
            slidingElementsSelector: "body",
            buttonsSelector:
                ".menu-toggle, .offcanvas-close, .offcanvas-overlay",
            beforeOpen: function () {
                $(".offcanvas-overlay").fadeIn();
            },
            beforeClose: function () {
                $(".offcanvas-overlay").fadeOut();
            },
        });

        AOS.init({
            offset: 75,
            duration: 700,
        });
    } catch {}

    $(".back-to-top").on("click", function () {
        $("html, body").animate({ scrollTop: 0 }, 2000);
    });

    $(".expand-toggle").on("click", function (e) {
        e.preventDefault();
        $(this).prev().toggleClass("show");
        $(this).find("strong").toggleText("Xem thêm", "Rút gọn");
        $(this).find("span").toggleClass("lnr-chevron-down lnr-chevron-up");
    });

    if ($(window).width() < 1024) {
        $(".has-sub .toggle").on("click", function () {
            $(this).next().slideToggle();
            $(this).toggleClass("fa-chevron-down fa-chevron-up");
        });

        $("header").toggleClass("scrolling", $(window).scrollTop() > 0);

        $(window).on("scroll", function () {
            $("header").toggleClass("scrolling", $(window).scrollTop() > 0);
        });

        $(".subnav-toggle").on("click", function () {
            $(this).prev().toggleClass("show");
            $(this).find("em").toggleClass("fa-caret-right fa-caret-left");
        });

        try {
            $(".subnav ul").scrollLeft(
                $(".subnav .active").position().left - 15
            );
        } catch (error) {}
    }

    // if ($('.homepage').length > 0) {
    //     if ($(window).width() > 1024) {
    //         sal({
    //             threshold: 0.2,
    //             // once: false
    //         });
    //     }

    // }

    // $(".apply-frm h4").text(
    //     $("input[name='ctl00$phMain$jobApply$txtPosition']").val()
    // );

    $(".change-url").on("change", function () {
        window.location.assign($(this).val());
    });

    $(".search-link").on("click", function (e) {
        e.preventDefault();
        $(".search-popup").fadeIn();
        $("body").addClass("overflow-hidden");
    });
    $(".close-search").on("click", function (e) {
        e.preventDefault();
        $("body").removeClass("overflow-hidden");
        $(".search-popup").fadeOut();
    });

    productDetailSlide();
    productSlider();
    setBackgroundElement();
    indexTabslet();
});

$.fn.extend({
    toggleText: function (a, b) {
        return this.text(this.text() == b ? a : b);
    },
});

try {
    const menuLeftMapping = new MappingListener({
        selector: ".menu-wrapper-left",
        mobileWrapper: ".offcanvas-wrap",
        mobileMethod: "appendTo",
        desktopWrapper: ".header-left",
        desktopMethod: "appendTo",
        breakpoint: 1024.98,
    }).watch();

    const menuRightMapping = new MappingListener({
        selector: ".menu-wrapper-right",
        mobileWrapper: ".offcanvas-wrap",
        mobileMethod: "appendTo",
        desktopWrapper: ".header-right",
        desktopMethod: "prependTo",
        breakpoint: 1024.98,
    }).watch();
} catch {}

const bannerSwiper = new Swiper(".main-banner-swiper .swiper-container", {
    pagination: {
        clickable: true,
        el: ".main-banner-swiper .swiper-pagination",
    },
    loop: true,
    autoplay: {
        delay: 3000,
        disableOnInteraction: true,
    },
    // Navigation arrows
    navigation: {
        nextEl: ".main-banner-swiper .swiper-btn.swiper-next",
        prevEl: ".main-banner-swiper .swiper-btn.swiper-prev",
    },
});

var firstload = true;
$(".video video").on("play", function () {
    if (!firstload) {
        bannerSwiper.autoplay.start();
        firstload = false;
        return;
    }
    bannerSwiper.autoplay.stop();
});

$(".video video").on("ended", function () {
    bannerSwiper.autoplay.start();
});

// var vid = document.querySelector(".video video");

// try {
//     vid.onended = function () {
//         bannerSwiper.autoplay.start();
//     };
// } catch (error) {}

// bannerSwiper.on("init", function () {
//     bannerSwiper.autoplay().stop();
//     if (vid.ended) {
//         bannerSwiper.autoplay().start();
//         console.log("ended");
//     } else {
//         bannerSwiper.autoplay().stop();
//         console.log("not ended");
//     }
// });

const home1Swiper = new Swiper(".home-1-swiper .swiper-container", {
    spaceBetween: 15,
    slidesPerView: 3,
    slidesPerColumn: 2,
    slidesPerGroup: 1,
    slidesPerColumnFill: "row",
    breakpoints: {
        768: {
            spaceBetween: 15,
            slidesPerView: 2.5,
            slidesPerColumn: 2,
            slidesPerGroup: 1,
        },
        576: {
            spaceBetween: 5,
            slidesPerView: 2,
            slidesPerColumn: 2,
            slidesPerGroup: 1,
            autoplay: {
                delay: 3000,
            },
            loop: true,
        },
    },
    navigation: {
        nextEl: ".home-1-swiper .swiper-btn.swiper-next",
        prevEl: ".home-1-swiper .swiper-btn.swiper-prev",
    },
});

const home2Swiper = new Swiper(".home-2-swiper .swiper-container", {
    spaceBetween: 15,
    slidesPerView: 4,
    autoplay: {
        delay: 3000,
    },
    loop: true,
    breakpoints: {
        1024: {
            spaceBetween: 15,
            slidesPerView: 3,
        },
        576: {
            spaceBetween: 10,
            slidesPerView: 2,
        },
    },
    navigation: {
        nextEl: ".home-2-swiper .swiper-btn.swiper-next",
        prevEl: ".home-2-swiper .swiper-btn.swiper-prev",
    },
});

const home3Swiper = new Swiper(".home-3-swiper .swiper-container", {
    slidesPerView: 1,
    navigation: {
        nextEl: ".home-3-swiper .swiper-btn.swiper-next",
        prevEl: ".home-3-swiper .swiper-btn.swiper-prev",
    },
});

const newsSlider = new Swiper(".news-slider .swiper-container", {
    slidesPerView: 3,
    spaceBetween: 30,
    breakpoints: {
        1024: {
            slidesPerView: 2.25,
            spaceBetween: 20,
        },
        576: {
            slidesPerView: 1.25,
            spaceBetween: 15,
        },
    },
    navigation: {
        nextEl: ".news-slider .swiper-next",
        prevEl: ".news-slider .swiper-prev",
    },
});
let initValue = 115;
let countSlide = $(".about-2-thumb .swiper-slide").length;
// console.log(countSlide);
$(".about-2-thumb .swiper-container").css("max-width", initValue * countSlide);

const aboutHistoryThumbSlider = new Swiper(".about-2-thumb .swiper-container", {
    watchSlidesProgress: true,
    slidesPerView: "auto",
    breakpoints: {
        768: {
            slidesPerView: 3,
        },
    },
});

const aboutHistorySlider = new Swiper(".about-2-swiper .swiper-container", {
    slidesPerView: 1,
    clickable: true,
    // If we need pagination
    pagination: {
        el: ".about-2-swiper .swiper-pagination",
    },

    // Navigation arrows
    navigation: {
        nextEl: ".about-2-swiper .swiper-btn.swiper-next",
        prevEl: ".about-2-swiper .swiper-btn.swiper-prev",
    },
    thumbs: {
        swiper: aboutHistoryThumbSlider,
    },
});
const about6Slider = new Swiper(".about-6-swiper .swiper-container", {
    clickable: true,
    spaceBetween: 10,
    slidesPerView: 4,
    slidesPerColumn: 2,
    slidesPerGroup: 1,
    autoplay: {
        delay: 2500,
    },
    breakpoints: {
        576: {
            spaceBetween: 10,
            slidesPerView: 3,
            slidesPerColumn: 2,
            slidesPerGroup: 1,
        },
    },
    pagination: {
        el: ".about-6-swiper .swiper-pagination",
    },

    // Navigation arrows
    navigation: {
        nextEl: ".about-6-swiper .swiper-btn.swiper-next",
        prevEl: ".about-6-swiper .swiper-btn.swiper-prev",
    },
});
const giaCongSwiper = new Swiper(".giacong-2-swiper .swiper-container", {
    spaceBetween: 0,
    slidesPerView: 4,
    breakpoints: {
        768: {
            slidesPerView: 2,
        },
    },
});

function productDetailSlide() {
    var productDetailThumbnail = new Swiper(
        ".product-detail-thumbnail .swiper-container",
        {
            slidesPerView: 4,
            spaceBetween: 27,
            observer: true,
            observeParents: true,
            navigation: {
                nextEl: ".product-detail-thumbnail .swiper-next",
                prevEl: ".product-detail-thumbnail .swiper-prev",
            },
            breakpoints: {
                768: {
                    slidesPerView: 3.25,
                    spaceBetween: 10,
                },
                576: {
                    slidesPerView: 2.25,
                    spaceBetween: 10,
                },
            },
        }
    );

    var productDetailImages = new Swiper(
        ".product-detail-images .swiper-container",
        {
            observer: true,
            observeParents: true,
            thumbs: {
                swiper: productDetailThumbnail,
            },
        }
    );
}

function productSlider() {
    $(".product-slider").each(function (index) {
        var $this = $(this);
        if ($(this).data("slides") !== undefined) {
            var initSlide = $(this).data("slides");
        } else {
            var initSlide = 4;
        }
        $this.addClass("product-slider-" + index);
        $this.find(".swiper-prev").addClass("product-slider-prev-" + index);
        $this.find(".swiper-next").addClass("product-slider-next-" + index);
        $this
            .find(".swiper-pagination")
            .addClass("product-slider-pagination-" + index);

        var productSlider = new Swiper(
            ".product-slider-" + index + " .swiper-container",
            {
                slidesPerView: initSlide,
                spaceBetween: 30,
                navigation: {
                    prevEl: ".product-slider-prev-" + index,
                    nextEl: ".product-slider-next-" + index,
                },
                pagination: {
                    el: ".product-slider-pagination-" + index,
                    clickable: true,
                },
                loop: true,
                autoplay: {
                    delay: 3000,
                },
                breakpoints: {
                    1280: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                        freeMode: true,
                    },
                },
            }
        );
    });
}
function setBackgroundElement() {
    $("[setBackground]").each(function () {
        var background = $(this).attr("setBackground");
        $(this).css({
            "background-size": "cover",
            "background-position": "center center",
            "background-repeat": "no-repeat",
            "background-image": "url(" + background + ")",
        });
    });

    if ($(window).width() > 1024) {
        $("[setBackgroundDesktop]").each(function () {
            var background = $(this).attr("setBackgroundDesktop");
            $(this).css({
                "background-size": "cover",
                "background-position": "center center",
                "background-repeat": "no-repeat",
                "background-image": "url(" + background + ")",
            });
        });
    } else {
        $("[setBackgroundMobile]").each(function () {
            var background = $(this).attr("setBackgroundMobile");
            $(this).css({
                "background-size": "cover",
                "background-position": "center center",
                "background-repeat": "no-repeat",
                "background-image": "url(" + background + ")",
            });
        });
    }

    $("[setBackgroundBottom]").each(function () {
        var background = $(this).attr("setBackgroundBottom");
        $(this).css({
            "background-size": "100%",
            "background-position": "center bottom",
            "background-repeat": "no-repeat",
            "background-image": "url(" + background + ")",
        });
    });
}
function indexTabslet() {
    $(".tabs").tabslet();
}

const subBannerSlider = new Swiper(".sub-banner-slider .swiper-container", {
    autoplay: {
        delay: 3000,
    },
});
